<template>
  <div class="container">
    <div class="about__title title">О компании</div>
    <div class="about__text text">
      «Palladi.ru» – интернет-супермаркет, специализирующийся на доставке
      продуктов питания и сопутствующих товаров на дом и в офис.
    </div>
    <div class="typography">
      <iframe
        :src="videoCompany"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
      <h2>Реквизиты</h2>
      <p>
        {{ requisite.name }} <br />
        {{ requisite.number }}
      </p>
      <p>
        {{ requisite.mailingAddress }} <br />
        {{ requisite.legalAddress }}
      </p>
      <p>Тел: {{ requisite.phone }}</p>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    videoCompany() {
      return this.$store.state.requisite.videoCompany;
    },
    requisite() {
      return this.$store.state.requisite.individualEntrepreneur;
    },
  },
};
</script>
